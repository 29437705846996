
import {
  defineComponent,
  nextTick,
  provide,
  onMounted,
  watch,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import LoadingAnimation from "./components/custom-ui/LoadingAnimation.vue";
import { globalLoading } from "./store";
import { loadStripe } from "@stripe/stripe-js";
import logger from "@/helpers/logger";

export default defineComponent({
  components: { LoadingAnimation },
  name: "App",
  setup() {
    const route = useRoute();

    const isGlobalLoading = computed(() => globalLoading.value);

    function scrollToTop() {
      nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        window.parent.postMessage("scroll-to-top", "*");
      });
    }

    function applyBackground() {
      const appElement = document.getElementById("app");
      if (appElement) {
        if (route.name !== "therapist-directory-profile") {
          appElement.style.backgroundColor = "#F4F4EF";
        } else {
          appElement.style.backgroundColor = "#fff";
        }
      }
    }

    function controlHubSpotChatWidget() {
      if (route.name === "therapist-directory-profile") {
        window.HubSpotConversations?.widget?.remove();
      } else {
        window.HubSpotConversations?.widget?.load();
      }
    }

    onMounted(() => {
      applyBackground();
      controlHubSpotChatWidget();
    });

    onMounted(async () => {
      try {
        await loadStripe(`${_STRIPE_PUBLIC_KEY_}`);

        if (typeof window.Stripe === "undefined") {
          logger.info(
            "Stripe.js is not accessible via `window.Stripe`, possibly due to blockers."
          );
        }
      } catch (error: any) {
        logger.error("Error loading Stripe.js:", error);
      }
    });

    watch(route, () => {
      applyBackground();
      controlHubSpotChatWidget();
    });

    function goToLogin() {
      window.parent.postMessage("go-to-login", "*");
    }

    function goToContactPage() {
      window.parent.postMessage("go-to-contact-page", "*");
    }

    function emitNewFrameHeight(height: any) {
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      );
    }

    provide("emit-new-frame-height", emitNewFrameHeight);
    provide("scroll-to-top", scrollToTop);
    provide("go-to-login", goToLogin);
    provide("go-to-contact-page", goToContactPage);

    return {
      globalLoading: isGlobalLoading,
    };
  },
});
