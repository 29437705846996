interface Filters {
  [key: string]: any;
  specialisms?: Set<number>;
  dayOfWeek?: Set<string>;
  timeOfDay?: Set<string>;
  personalityTypes?: string[];
}

interface SerializedFilters {
  [key: string]: any;
  specialisms?: number[];
  dayOfWeek?: string[];
  timeOfDay?: string[];
  personalityTypes?: string[];
}

export function isSet(value: any): value is Set<any> {
  return value instanceof Set;
}

export function serializeFilters(filters: Filters): SerializedFilters {
  const serialized: SerializedFilters = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (isSet(value)) {
      serialized[key] = Array.from(value);
    } else if (value !== undefined) {
      serialized[key] = value;
    }
  });

  return serialized;
}

export function deserializeFilters(serialized: SerializedFilters): Filters {
  const deserialized: Filters = {};

  Object.entries(serialized).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      switch (key) {
        case "specialisms":
        case "dayOfWeek":
        case "timeOfDay":
          deserialized[key] = new Set(value);
          break;
        default:
          deserialized[key] = value;
      }
    } else {
      deserialized[key] = value;
    }
  });

  return deserialized;
}

export function saveFiltersToStorage(filters: Filters): void {
  const serialized = serializeFilters(filters);
  localStorage.setItem("appliedFilters", JSON.stringify(serialized));
  localStorage.setItem("filtersLastUpdated", new Date().toISOString());
}

export function loadFiltersFromStorage(): Filters | null {
  const savedFilters = localStorage.getItem("appliedFilters");
  if (!savedFilters) return null;

  try {
    const serialized = JSON.parse(savedFilters);
    return deserializeFilters(serialized as SerializedFilters);
  } catch (error) {
    console.error("Error loading filters from storage:", error);
    return null;
  }
}

export function clearStoredFilters(): void {
  localStorage.removeItem("appliedFilters");
  localStorage.removeItem("filtersLastUpdated");
}
